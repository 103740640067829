<template>
  <div class="content bg-text m-16 px-16">
    <div class="py-12 df-middle" style="line-height: 16px;">
      <t as="p" path="transactionhistory_20" class="c-white f-md f-bold flex-1">First Deposit bonus statistic</t>
      <router-link to="/" class="c-success f-sm df-middle">
        <t path="transactionhistory_21" style="margin-right: 3px;">Terms of event</t>
        <Icon name="warn" sm />
      </router-link>
    </div>
    <Line class="bonus-line" />
    <div class="py-16 df-middle df-between f-sm c-white">
      <div>
        <t path="transactionhistory_22" class="c-tip mr-8">Bonus claimed</t>
        <Money class="f-bold" :v="1000 * 10000" />
      </div>
      <div class="divider-line"></div>
      <div>
        <t path="transactionhistory_23" class="c-tip mr-8">Volume required</t>
        <Money class="f-bold" :v="10000 * 10000" />
      </div>
    </div>
    <div class="pb-16 df-middle f-sm">
      <t path="transactionhistory_24" class="c-tip">Volume traded</t>
      <div class="progress flex-1">
        <div class="bar bg-success" style="width: 30%;"></div>
      </div>
      <Money class="f-bold c-success" :v="3000 * 10000" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'
import Line from '@/components/Line.vue'
import Money from '@/components.global/Money'

export default defineComponent({
  name: 'TheDepositBonus',
  components: { Money, Line, Icon },
})
</script>

<style scoped lang="scss">
.content {
  border-radius: 2px;
}

.bonus-line::after {
  border-top: 1px solid rgba(207, 216, 227, 0.3);
}

.divider-line {
  height: 16px;
  border-left: 1px solid rgba(161, 174, 182, 0.54);
}

.progress {
  height: 8px;
  border-radius: 4px;
  background: var(--color-light);
  margin: 0 12px 0 8px;
  position: relative;
}

.bar {
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
</style>
