
import ColorText from '@/components/ColorText.vue'
import { defineComponent, ref } from 'vue'
import Icon from '@/components/Icon.vue'
import Money from '@/components.global/Money'
import Time from '@/components/Time.vue'
import TheTradeLimitDisplay from '@/modules/trade/components/TheTradeLimitDisplay.vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheHistoryItem',
  components: { Pic, TheTradeLimitDisplay, Time, Money, Icon, ColorText },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const show = ref(false)

    const isShow = () => {
      show.value = !(show.value)
    }

    // const CloseType = ['', '', '', 'Manual', 'Take profit', 'Stop loss', 'Stop out', 'Auto']
    const CloseType = ['', '', '', 'transactionhistory_15', 'transactionhistory_18', 'transactionhistory_17', 'transactionhistory_16', 'transactionhistory_19']

    return {
      show,
      isShow,
      CloseType,
    }
  },
})
