<template>
  <div class="mt-8 history py-0">
    <div class="history-title f-md df-middle" @click="isShow">
      <div class="title-time mr-6">
        <Time format="MM-dd-yy" :time="prop('closeTime', order)" />
      </div>
      <div class="f-bold title-code mr-20">{{ prop('code', order)}}</div>
      <t as="div" :path="CloseType[order.closeType]" class="f-xs"></t>
      <div class="flex-1 df-middle" style="justify-content: flex-end;">
        <Money class="f-medium f-nm" #="{text, origin}" :v="prop('profitLoss', order)">
          <ColorText :value="origin">{{text}}</ColorText>
        </Money>
        <Pic v-if="order.source" src="trade/documentary" width="16" height="16" class="ml-8" />
        <Icon
          :name="show ? 'caret-up' : 'caret-down'"
          class="ml-8 list-arrow" sm
        />
      </div>
    </div>
    <div v-show="show" class="history-detail d-f f-sm pt-12 pb-4">
      <div class="flex-1">
        <p class="mb-8">
          <t path="transactionhistory_3" class="mr-4 c-title">Volume</t>
          <span class="value">{{prop('weight', order)}} {{prop('unit', order)}}</span>
        </p>
        <p class="mb-8">
          <t path="transactionhistory_5" class="mr-4 c-title">Margin</t>
          <Money class="f-medium" :v="prop('amount', order)" />
          <Icon v-if="order.isJuan" name="coupon" class="c-title f-medium ml-4" sm />
        </p>
        <p class="mb-8">
          <t path="transactionhistory_7" class="mr-4 c-title">T/P</t>
          <TheTradeLimitDisplay class="f-medium" :value="prop('stopProfitPoint', order)" />
        </p>
        <p class="mb-8">
          <t path="transactionhistory_9" class="mr-4 c-title">Entry Price</t>
          <span class="f-medium">{{prop('createPrice', order)}}</span>
        </p>
        <p class="mb-8">
          <t path="transactionhistory_11" class="mr-4 c-title">Close Price</t>
          <span class="f-medium">{{prop('closePrice', order)}}</span>
        </p>
        <p class="mb-8">
          <t path="transactionhistory_13" class="mr-4 c-title">Commission</t>
          <Money class="f-medium" :v="prop('fee', order)" />
        </p>
        <p class="mb-8">
          <t path="transactionhistory_25" class="mr-4 c-title">Leverage</t>
          <t class="f-medium" :path="prop('leverage', order)"></t>
        </p>
      </div>
      <div>
        <p class="mb-8">
          <t path="transactionhistory_4" class="mr-4 c-title">Direction</t>
          <t custom #="{td, t}">
            <ColorText class="f-medium" :display="[t('symbol_10') || 'Sell', t('symbol_9') || 'Buy']"
              :value="prop('type', order) - 2" />
          </t>
        </p>
        <p class="mb-8">
          <t path="transactionhistory_6" class="mr-4 c-title">Profit</t>
          <Money class="f-medium" #="{text, origin}" :v="prop('profitLoss', order)">
            <ColorText :value="origin">{{text}}</ColorText>
          </Money>
        </p>
        <p class="mb-8">
          <t path="transactionhistory_8" class="mr-4 c-title">S/L</t>
          <TheTradeLimitDisplay class="f-medium" :value="prop('stopLossPoint', order)" />
        </p>
        <p class="mb-8">
          <t path="transactionhistory_10" class="mr-4 c-title">Entry Time</t>
          <Time class="f-medium f-xs" format="MM-dd-yy HH:mm:ss" :time="prop('createTime', order)" />
        </p>
        <p class="mb-8">
          <t path="transactionhistory_12" class="mr-4 c-title">Close Time</t>
          <Time class="f-medium f-xs" format="MM-dd-yy HH:mm:ss" :time="prop('closeTime', order)" />
        </p>
        <p class="mb-8">
          <t path="transactionhistory_14" class="mr-4 c-title">Swap</t>
          <Money class="f-medium" :v="prop('deferredFee', order)"/>
          <span class="f-medium"> ({{ prop('days', order) }}days)</span>
        </p>
        <p class="mb-8">
          <t path="transactionhistory_29" class="mr-4 c-title">Profit per pip</t>
          <money class="f-medium" :v="prop('floatProfit', order)" #={value}>{{value + ' ' + prop('ykUnit', order)}}</money>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ColorText from '@/components/ColorText.vue'
import { defineComponent, ref } from 'vue'
import Icon from '@/components/Icon.vue'
import Money from '@/components.global/Money'
import Time from '@/components/Time.vue'
import TheTradeLimitDisplay from '@/modules/trade/components/TheTradeLimitDisplay.vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheHistoryItem',
  components: { Pic, TheTradeLimitDisplay, Time, Money, Icon, ColorText },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const show = ref(false)

    const isShow = () => {
      show.value = !(show.value)
    }

    // const CloseType = ['', '', '', 'Manual', 'Take profit', 'Stop loss', 'Stop out', 'Auto']
    const CloseType = ['', '', '', 'transactionhistory_15', 'transactionhistory_18', 'transactionhistory_17', 'transactionhistory_16', 'transactionhistory_19']

    return {
      show,
      isShow,
      CloseType,
    }
  },
})
</script>

<style scoped lang="scss">
  .history {
    border-radius: 2px;
    overflow: hidden;
  }

  .history-title {
    height: 40px;
  }

  .title-time {
    min-width: 80px;
    max-width: 80px;
  }

  .title-code {
    min-width: 60px;
    max-width: 60px;
  }

  .list-arrow {
    vertical-align: top;
    position: relative;
    top: 1px;
  }

  .history-detail {
    border-top: 1px solid #D5DEE8;
  }

</style>
